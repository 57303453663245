import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import HeaderGeneric from '../components/HeaderGeneric'
import { Waypoint } from 'react-waypoint'
import Nav from '../components/Nav'
import PortfolioCard from '../components/PortfolioCard'

class Portfolio extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stickyNav: false,
    }
  }

  _handleWaypointEnter = () => {
    this.setState(() => ({ stickyNav: false }))
  }

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }))
  }

  render() {
    return (
      <Layout>
        <Helmet title="Crabber-Webservice - Portfolio" />
        <HeaderGeneric />
        <Waypoint
          onEnter={this._handleWaypointEnter}
          onLeave={this._handleWaypointLeave}
        ></Waypoint>
        <Nav sticky={this.state.stickyNav} active="portfolio" />
        <div id="main">
          <div className="main">
            <header className="major">
              <h2>Portfolio</h2>
              <p>
                Durch Crabber Webservice erstellt oder betreut.
              </p>
            </header>
            <StaticQuery
              query = {graphql`
                query PortfolioQuery {
                  allNodePortfolio {
                    edges {
                      node {
                        id
                        title
                        field_web_adresse {
                          title
                          uri
                        }
                        body {
                          processed
                        }
                        relationships {
                          field_seiten_img {
                            relationships {
                              field_media_image {
                                localFile {
                                  childImageSharp {
                                    fluid(sizes: "150") {
                                      ...GatsbyImageSharpFluid_withWebp
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              `}
              render = {data => (
                data.allNodePortfolio.edges.map(portfolio => (
                  <PortfolioCard
                    key={portfolio.node.id}
                    title={portfolio.node.title}
                    content={portfolio.node.body.processed}
                    image={portfolio.node.relationships.field_seiten_img ? portfolio.node.relationships.field_seiten_img.relationships.field_media_image.localFile.childImageSharp.fluid : '' }
                    link={portfolio.node.field_web_adresse ? [portfolio.node.field_web_adresse.title, portfolio.node.field_web_adresse.uri] : '' }
                  />
                ))
              )}
            />
          </div>
        </div>
      </Layout>
    )
  }
}

export default Portfolio
