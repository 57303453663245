import React from 'react'
import Img from 'gatsby-image';

const PortfolioTeaser = ({ title, content, image, link }) => (
  <div className="portfolio--teaser card">
    {image &&
      <span className="card--image image">
        <Img
          fluid={image}
          style={{width:`150px`, height:`150px`}}
        />
      </span>
    }
    <div className="card--content">
      <header className="card--header">
        <h2>{title}</h2>
      </header>
      <div
        className="card--body"
        dangerouslySetInnerHTML={{ __html: content }}
      />
      {link &&
        <div className="card--link">
          <a href={link[1]} target="_blank" rel="noopener noreferrer">{link[0]}</a>
        </div>
      }
    </div>
  </div>
)

export default PortfolioTeaser